@import "styles/variables";

.arcade {
    & * {
        @apply font-cashsans;
    }
}

.defaultLayout {
    @apply px-15;

    @screen md {
        @apply px-12;
    }

    @screen sm {
        @apply px-2;
    }
}

.bareLayout,
.simpleLayout {
    @apply px-16 pt-4;

    @screen md {
        @apply px-12 pt-4;
    }

    @screen sm {
        @apply px-2 pt-2;
    }
}

.main {
    @apply pb-16;
    padding-top: $newUSNavHeaderHeight;

    @screen md {
        @apply pb-12;
        padding-top: $headerHeight;
    }

    @screen sm {
        @apply pb-4;
        padding-top: $mobileHeaderHeight;
    }
}

.mainNewNav {
    @apply pb-16;
    padding-top: $newUSNavHeaderHeight;

    @screen md {
        @apply pb-12;
        padding-top: $newUSNavHeaderHeight;
    }

    @screen sm {
        @apply pb-4;
        padding-top: $newUSNavHeaderHeight;
    }
}

.hideNavigationMain:not(.main) {
    @apply pb-16;

    @screen md {
        @apply pb-12;
    }

    @screen sm {
        @apply pb-4;
    }
}

.hideSmartBanner {
    margin-top: 0 !important;
}
