.countryCards {
    @screen lg {
        @apply absolute top-1/2 left-16 right-16;
        transform: translateY(-50%);
    }

    @screen md {
        @apply absolute top-1/2 left-12 right-12;
        transform: translateY(-50%);
    }

    @screen sm {
        @apply mt-4;
    }
}

.localeLink {
    @apply col-span-2 border-0-1 border-gray80 rounded-xlg p-4;
}

.countryName {
    @apply mt-4;
}

.heading {
    @apply heading6XL;
}

.subheading {
    @apply contentXL mt-4;
}
