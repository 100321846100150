.responsiveGrid {
    @apply grid;

    @screen sm {
        @apply grid-cols-4 gap-4 p-4;
    }

    @screen md {
        @apply grid-cols-8 gap-6 p-12;
    }

    @screen lg {
        @apply grid-cols-12 gap-6 p-16;
    }
}

.fullWidth {
    @apply px-0;
}

.fullHeight {
    @apply pb-0 pt-0;
}

.autoFill {
    @screen sm {
        grid-template-columns: repeat(auto-fill, minmax(21.7%, 1fr));
    }
}
